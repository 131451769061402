exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app/[...].tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-books-tsx": () => import("./../../../src/pages/books.tsx" /* webpackChunkName: "component---src-pages-books-tsx" */),
  "component---src-pages-collections-tsx": () => import("./../../../src/pages/collections.tsx" /* webpackChunkName: "component---src-pages-collections-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-gift-cards-gift-card-index-tsx": () => import("./../../../src/pages/gift-cards/[...giftCardIndex].tsx" /* webpackChunkName: "component---src-pages-gift-cards-gift-card-index-tsx" */),
  "component---src-pages-gift-cards-index-tsx": () => import("./../../../src/pages/gift-cards/index.tsx" /* webpackChunkName: "component---src-pages-gift-cards-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-organic-plugtrays-in-stock-tsx": () => import("./../../../src/pages/organic-plugtrays-in-stock.tsx" /* webpackChunkName: "component---src-pages-organic-plugtrays-in-stock-tsx" */),
  "component---src-pages-organic-pots-in-stock-tsx": () => import("./../../../src/pages/organic-pots-in-stock.tsx" /* webpackChunkName: "component---src-pages-organic-pots-in-stock-tsx" */),
  "component---src-pages-plants-by-zone-tsx": () => import("./../../../src/pages/plants-by-zone.tsx" /* webpackChunkName: "component---src-pages-plants-by-zone-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-blog-index-tsx": () => import("./../../../src/templates/blogIndex.tsx" /* webpackChunkName: "component---src-templates-blog-index-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-collection-tsx": () => import("./../../../src/templates/collection.tsx" /* webpackChunkName: "component---src-templates-collection-tsx" */),
  "component---src-templates-genus-page-tsx": () => import("./../../../src/templates/genus-page.tsx" /* webpackChunkName: "component---src-templates-genus-page-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-products-tagged-tsx": () => import("./../../../src/templates/ProductsTagged.tsx" /* webpackChunkName: "component---src-templates-products-tagged-tsx" */)
}

