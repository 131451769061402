// custom typefaces
import 'typeface-montserrat';
import 'typeface-merriweather';

import 'prismjs/themes/prism.css';
import { configureAmplify } from './src/utils/auth';
import log from 'loglevel';
import * as config from './config';
import type { GatsbyBrowser } from 'gatsby';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const ENV_CONFIG = config.get();

export const onClientEntry: GatsbyBrowser['onClientEntry'] = async () => {
  const isLocal = window.location.hostname === 'localhost';

  if (isLocal) {
    log.setDefaultLevel('info');
  } else {
    log.setDefaultLevel(ENV_CONFIG.logLevel);
  }
  configureAmplify();

  if (!isLocal) {
    log.info('initting sentry');
    Sentry.init({
      dsn: ENV_CONFIG.sentryDSN,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0.04,
      release: process.env.GATSBY_RELEASE_SHA,
    });
  } else {
    log.info('omitting sentry for localhost');
  }
};
