const DEV_CDN_URL = 'https://dev.mountainvalleygrowers.com';
const DEV_API_URL = 'https://api.dev.mountainvalleygrowers.com';
const DEV_API_GATEWAY_URL =
  'https://f8j0jtasz7.execute-api.us-west-2.amazonaws.com';

const PROD_CDN_URL = 'https://mountainvalleygrowers.com';
const PROD_API_URL = 'https://api.mountainvalleygrowers.com';
const PROD_API_GATEWAY_URL =
  'https://vxoj24wkji.execute-api.us-west-2.amazonaws.com';

const ENVIRONMENT_CONFIG = {
  local: {},
  dev: {
    region: 'us-west-2',
    siteUrl: DEV_CDN_URL,
    authorizeAcceptJsUrl: 'https://jstest.authorize.net/v1/Accept.js',
    authorizeNetApiLoginId: '7kaL2RS9rpm',
    authorizeNetClientKey:
      '5S2ysQ493FaV3mc43xa2V2MkCTAH763Tw3a2FH7mss4FVyaXm6aRerJEmBCNkS72',
    publicApi: `${DEV_CDN_URL}/apiV0/public`,
    graphqlBuildApi: `${DEV_API_GATEWAY_URL}/apiV0/graphql-build`,
    graphqlBuildApiKey: 'Iy487AwiCz8E7eMUuLjaqj06vZPW1343v7QcELG5',
    graphqlApi: `${DEV_API_URL}/graphql`,
    graphqlApiCdn: `${DEV_CDN_URL}/apiV0/graphql`,
    grahpqlApiCdnSigningHost: `${DEV_API_GATEWAY_URL}/apiV0/graphql`,
    cognitoUserPoolId: 'us-west-2_XGvZNrzXa',
    cognitoWebClientId: '5o8hqhp50q6g1dfgbsvkqe4agu',
    cognitoIdentityPoolId: 'us-west-2:eac2caca-d7a7-47d4-bfcb-b8f0527074d5',
    logLevel: 'warn',
    imageCdnPrefix: `${DEV_CDN_URL}/organic-plants-assets`,
    useCdnImages: true,
    googleTag: 'G-VM4NJB1E0G',
    sentryDSN:
      'https://5ac3547fcaba44499461d363533eef76@o4504482903949312.ingest.sentry.io/4504482957688832',
    loginEnabled: true,
  },
  prod: {
    region: 'us-west-2',
    siteUrl: PROD_CDN_URL,
    authorizeAcceptJsUrl: 'https://js.authorize.net/v1/Accept.js',
    authorizeNetApiLoginId: '7rs9K9Y7h9',
    authorizeNetClientKey:
      '7zcHUyhcY229U9p6j5vMcbWKm545H3s7VGjX8UR8XGwtjF6ZaE74dmWwZuY8WHm5',
    publicApi: `${PROD_CDN_URL}/apiV0/public`,
    graphqlBuildApi: `${PROD_API_GATEWAY_URL}/apiV0/graphql-build`,
    graphqlBuildApiKey: 'dsZULNYObf3cl4EZlxIPb3RWrDTbW6kJ7agHGzx5',
    graphqlApi: `${PROD_API_URL}/graphql`,
    graphqlApiCdn: `${PROD_CDN_URL}/apiV0/graphql`,
    grahpqlApiCdnSigningHost: `${PROD_API_GATEWAY_URL}/apiV0/graphql`,
    cognitoUserPoolId: 'us-west-2_zgItoCJTT',
    cognitoWebClientId: '7favv9pjjtrkodhcj2vtjbl6h6',
    cognitoIdentityPoolId: 'us-west-2:3fcd83c7-a4ba-4518-8faf-3ee6dce834a0',
    logLevel: 'warn',
    imageCdnPrefix: `${PROD_CDN_URL}/organic-plants-assets`,
    useCdnImages: true,
    googleTag: 'G-NB3LGW65LQ',
    sentryDSN:
      'https://60488af511c2422c993eb398dc93e046@o4504482903949312.ingest.sentry.io/4504482905456640',
    loginEnabled: false,
  },
};

const get = () => {
  const env = process.env.GATSBY_ACORN_ENVIRONMENT || 'dev';
  return ENVIRONMENT_CONFIG[env];
};

const isDev = () => {
  const env = process.env.GATSBY_ACORN_ENVIRONMENT || 'dev';
  return env === 'dev';
};

module.exports = {
  // eslint-disable-line
  get,
  isDev,
};
