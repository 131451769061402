import _ from 'lodash';
import { Auth } from '@aws-amplify/auth';
import { Amplify } from '@aws-amplify/core';
// import store from 'store';
import log from 'loglevel';
import * as config from '../../config';

const ENV_CONFIG = config.get();

export const configureAmplify = () => {
  const fn = 'configureAmplify';
  log.debug(
    { fn, ENV_CONFIG, env: process.env.ACORN_ENVIRONMENT },
    'got environment config',
  );
  const oauth = {
    domain: ENV_CONFIG.cognitoDomain,
    scope: [
      'phone',
      'email',
      'profile',
      'openid',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: ENV_CONFIG.signinUrl,
    redirectSignOut: ENV_CONFIG.signoutUrl,
    responseType: 'code',
    options: {
      AdvancedSecurityDataCollectionFlag: true,
    },
  };

  Amplify.configure({
    // 'aws_appsync_graphqlEndpoint': 'https://iuipfrgogrbmlnxaphpt7p5mxi.appsync-api.us-west-2.amazonaws.com/graphql',
    // 'aws_appsync_region': 'us-west-2',
    graphql_endpoint: ENV_CONFIG.graphqlApi,
    graphql_endpoint_iam_region: 'us-west-2',
    aws_appsync_authenticationType: 'AWS_IAM',
    aws_cognito_identity_pool_id: ENV_CONFIG.cognitoIdentityPoolId,
    aws_cognito_region: 'us-west-2',
    aws_user_pools_id: ENV_CONFIG.cognitoUserPoolId,
    aws_user_pools_web_client_id: ENV_CONFIG.cognitoWebClientId,
    Auth: {
      identityPoolId: ENV_CONFIG.cognitoIdentityPoolId,
      region: 'us-west-2',
      userPoolId: ENV_CONFIG.cognitoUserPoolId,
      userPoolWebClientId: ENV_CONFIG.cognitoWebClientId,
      mandatorySignIn: false,
      oauth,
      authenticationFlowType: 'USER_PASSWORD_AUTH',
    },
  });
};

export const transformUser = (user) => {
  user = user || {};

  return {
    username: user.username,
    isAdmin: _.includes(
      _.get(user, [
        'signInUserSession',
        'accessToken',
        'payload',
        'cognito:groups',
      ]) || [],
      'admin',
    ),
  };
};

export const getUser = async () => {
  await getCredentials();
  try {
    const user = await Auth.currentAuthenticatedUser();
    const transformedUser = transformUser(user);
    return transformedUser;
  } catch (err) {
    log.trace({ err }, 'got error running getUser, no user logged in');
    return undefined;
  }
};

export const getIdentityId = async () => {
  try {
    const credentials = await Auth.currentCredentials();
    return _.get(credentials, 'params.IdentityId');
  } catch (err) {
    log.error('getIdentityId: got error', err);
    throw err;
  }
};

export const getCredentials = async () => {
  try {
    const creds = await Auth.currentCredentials();
    if (_.get(creds, 'code') === 'NotAuthorizedException') {
      log.error(
        { creds },
        'getCredentials: could not get valid credentials, amplify has a bug.  Need to reset localstorage and trying again.',
      );
      try {
        // store.clearAll();
        // TODO: figure out what to do here!
        window.location.reload(true);
      } catch (err) {
        log.error(
          { err },
          'getCredentials: got error applying workaround for amplify',
        );
        throw err;
      }
    }
    log.info({ creds }, 'getCredentials');
    return creds;
  } catch (err) {
    log.error({ err }, 'getCredentials: got error');
  }
};
